const standbyPrefixUrl = process.env.VUE_APP_STANDBY_PREFIX_URL;
const stayPrefixUrl = process.env.VUE_APP_STAY_PREFIX_URL;

const APP_STORE = 'https://apps.apple.com/app/id1507570835';
const PLAY_STORE = 'https://play.google.com/store/apps/details?id=co.twenty.stop.spread';

const ENTERPRISE_DEMO_FORM = 'https://www.healthytogether.io/enterprise#demo-form';
const EDUCATION_BYU_ARTICLE = 'https://www.heraldextra.com/news/local/education/college/byu-begins-fall-semester-with-high-hopes-and-expectations-during-covid-19/article_6405c97b-d189-5781-a14b-936b17c2c5bf.html';
const VIEW_DEMO = 'https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F427058682%3Fapp_id%3D122963&dntp=1&display_name=Vimeo&url=https%3A%2F%2Fvimeo.com%2F427058682&image=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F905768656_1280.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=vimeo';

const STANDBY_REGISTER = `${standbyPrefixUrl}healthytogether.io/register/?g=a#/start`;
const STANDBY_PROVIDER = `${standbyPrefixUrl}healthytogether.io/provider/#/sign-in`;
const STAY_SIGNIN = `${stayPrefixUrl}healthytogether.io/hello/#/sign-in`;
const STAY_SIGNUP = `${stayPrefixUrl}healthytogether.io/hello/#/register`;

export default {
  APP_STORE,
  PLAY_STORE,
  ENTERPRISE_DEMO_FORM,
  EDUCATION_BYU_ARTICLE,
  VIEW_DEMO,
  STANDBY_REGISTER,
  STANDBY_PROVIDER,
  STAY_SIGNIN,
  STAY_SIGNUP,
};

export {
  APP_STORE,
  PLAY_STORE,
  ENTERPRISE_DEMO_FORM,
  EDUCATION_BYU_ARTICLE,
  VIEW_DEMO,
  STANDBY_REGISTER,
  STANDBY_PROVIDER,
  STAY_SIGNIN,
  STAY_SIGNUP
};
