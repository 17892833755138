<template>
  <div>
    <div class="navbar" id="nav" @mouseleave="unselectMenu" ref="nav">
      <div class="links">
        <div
          class="logo-container"
          :class="logoContainerClasses"
          v-on:click="unselectMenu"
        >
          <router-link-escape to="home">
            <picture>
              <source
                :srcset="LOGO_TIGHT_WHITE"
                media="(min-width: 768px)"
                v-if="isHomePage"
              />
              <source
                :srcset="LOGO_TIGHT_BLACK"
                media="(min-width: 768px)"
                v-else
              />
              <source
                srcset="/images/HT-logo-small.png"
                media="(min-width: 480px)"
              />
              <img
                :src="LOGO_TIGHT_WHITE"
                alt="Healthy Together logo"
                v-if="isHomePage"
              />
              <img :src="LOGO_TIGHT_BLACK" alt="Healthy Together logo" v-else />
            </picture>
          </router-link-escape>
        </div>
        <div class="nav-list">
          <div
            v-for="(menu, index) in navigationItems"
            :key="index"
            class="nav-list-item"
            :ref="'menu-' + menu.meta.id"
            @mouseenter="setActiveNavMenu(menu, index)"
          >
            <h3 :class="navTitleClasses" class="nav-title">{{ menu.name }}</h3>
          </div>
          <div
            class="nav-menu"
            v-show="shouldDisplayMenu"
            @mouseleave="unselectMenu"
          >
            <div
              v-if="currentMenu.hasDynamicCategories"
              class="nav-items-container"
              :class="{ rows: currentMenuHasCategories }"
            >
              <div
                v-for="(item, index) in organizedMenu.navigation_items"
                :key="index"
                class="nav-item"
              >
                <template v-if="!currentMenuHasCategories">
                  <navigation-item :item="item" :clicked="unselectMenu" />
                </template>
                <template v-else>
                  <div class="nav-category-container">
                    <p class="nav-category-title">
                      {{ item.category_name }}
                    </p>
                    <div
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                      class="nav-category-children"
                    >
                      <navigation-item :item="child" :clicked="unselectMenu" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div v-else class="nav-list-groups">
              <div
                v-for="(category, index) in navigationProductGroups"
                :key="index"
                class="nav-list-group"
              >
                <h3 class="title">{{ category.name }}</h3>
                <ul v-if="locale">
                  <li
                    v-for="(product, index) in category.product_items"
                    :key="index"
                    class="nav-list-item"
                  >
                    <navigation-item
                      v-if="product.route"
                      :item="product.route"
                      :clicked="unselectMenu"
                    />
                    <div v-else class="nav-list-dummy">
                      <span class="content">
                        {{ product.name }}
                      </span>
                      <div class="description">
                        {{ product.description }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="nav-footer">
              <a class="contact-us" @click="openContactUs">
                Talk to our Team
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="action-wrapper">
        <!-- <btn custom-class="button-link small"
          text="Talk to our Team"
          href="contact-us">
        </btn> -->
        <btn
          v-if="isHomePage"
          custom-class="nav-signin button-link test-result-home"
          color="default"
          href="help/test-results"
          text="Test Results"
          @click.native="unselectMenu"
        />
        <btn
          v-else
          custom-class="nav-signin button-link"
          color="default"
          text="Test Results"
          href="help/test-results"
          @click.native="unselectMenu"
        />
      </div>
      <a class="hamburguer" @click="onMenuButtonClick">
        <img v-if="isHomePage" :src="ICON_MENU_WHITE" alt="Menu" height="32" />
        <img v-else :src="ICON_MENU_BLACK" alt="Menu" height="32" />
      </a>
      <div @click="onShaderClick" :class="shaderClasses"></div>
    </div>
    <div class="nav-full-menu" v-show="shouldDisplayFullMenu" :style="cssVars">
      <a @click="closeFullMenu" class="close-full-menu">
        <img :src="ICON_CLOSE" alt="Close" height="20" />
      </a>
      <div class="fullmenu-container">
        <div class="product-groups">
          <div
            v-for="category in navigationProductGroups"
            :key="category.meta.id"
            class="product-group"
          >
            <h3 class="title">{{ category.name }}</h3>
            <ul v-if="locale">
              <li
                v-for="product in category.product_items"
                :key="product.meta.id"
                class="product-item"
              >
                <navigation-item
                  v-if="product.route"
                  :item="product.route"
                  :clicked="closeFullMenu"
                />
                <div v-else class="product-dummy">
                  <span class="content">
                    {{ product.name }}
                  </span>
                  <div class="description">
                    {{ product.description }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-for="(menu, index) in fullMenu"
          :key="index"
          class="fullmenu-item"
          :class="{ full: menu.fullRowMenu }"
        >
          <p class="nav-category-title">
            {{ menu.name }}
          </p>
          <div class="fullmenu-links">
            <div
              v-for="(item, itemIndex) in menu.navigation_items"
              class="fullmenu-link-item"
              :key="itemIndex"
            >
              <navigation-item :item="item" :clicked="closeFullMenu" />
            </div>
          </div>
        </div>
      </div>
      <div class="fullmenu-footer">
        <a class="contact-us" @click="openContactUs">
          Talk to our Team
        </a>
        <btn
          custom-class="button-link"
          color="default"
          text="Test Results"
          href="help/test-results"
          @click.native="closeFullMenu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Btn from '@/components/basic/Btn.vue';
import NavigationItem from '@/components/structure/NavigationItem.vue';
import RouterLinkEscape from '@/components/structure/RouterLinkEscape';
import {
  LOGO_TIGHT_WHITE,
  LOGO_HORIZONTAL,
  LOGO_SMALL,
  MENU,
} from '@/constants/cloudinary';
import LOGO_TIGHT_BLACK from '@/assets/images/HT-logo-tight-black.png';
import { STAY_SIGNIN } from '@/constants/urls';
import { butter } from '@/buttercms';

const ICON_CLOSE = '/images/icon-close.png';
const ICON_CHEVRON_RIGHT = '/images/icon-chevron-right.png';
const ICON_MENU_BLACK = '/images/icon-menu-black.png';
const ICON_MENU_WHITE = '/images/icon-menu-white.png';

export default {
  name: 'Navbar',
  props: {
    isAndroidSmartBannerVisible: {
      type: Boolean,
    },
  },
  components: {
    RouterLinkEscape,
    Btn,
    NavigationItem,
  },
  data() {
    return {
      navigationItems: [],
      showNavMenu: false,
      showFullMenu: false,
      currentMenu: {},
    };
  },
  computed: {
    ...mapState(['navigationProductGroups', 'showPreview']),
    shaderClasses() {
      return [
        'shader',
        {
          active: this.shouldDisplayFullMenu,
          hidden: this.removeSideNavFromDom,
        },
      ];
    },
    menuAlt() {
      return this.showSideNav ? 'Closes menu' : 'Opens menu';
    },
    locale() {
      return this.$route.params.locale;
    },
    shouldDisplayMenu() {
      return this.locale && this.showNavMenu;
    },
    shouldDisplayFullMenu() {
      return this.locale && this.showFullMenu;
    },
    currentMenuHasCategories() {
      return (
        this.currentMenu.categories && this.currentMenu.categories.length > 0
      );
    },
    organizedMenu() {
      let menu = {};
      if (this.currentMenuHasCategories) {
        menu.navigation_items = this.currentMenu.categories;
        menu.navigation_items.forEach(category => {
          category.children = this.currentMenu.navigation_items.filter(
            x => x.category === category.category_id
          );
        });
        return menu;
      }
      return this.currentMenu;
    },
    fullMenu() {
      const menu = this.navigationItems.filter(x => {
        return x.meta.id !== 169650;
      });
      return menu;
    },
    isHomePage() {
      return this.$route.name === 'home';
    },
    logoContainerClasses() {
      return { 'is-home-page': this.isHomePage };
    },
    navTitleClasses() {
      return { 'nav-home': this.isHomePage };
    },
    cssVars() {
      const offset = window.innerWidth <= 320 ? '8rem' : '6.5rem';
      return {
        '--offsetDueToAndroidSmartBanner': this.isAndroidSmartBannerVisible
          ? offset
          : '1rem',
      };
    },
  },
  watch: {
    locale() {
      this.fetchNavigationItems();
    },
  },
  created() {
    this.LOGO_HORIZONTAL = LOGO_HORIZONTAL;
    this.LOGO_TIGHT_WHITE = LOGO_TIGHT_WHITE;
    this.LOGO_TIGHT_BLACK = LOGO_TIGHT_BLACK;
    this.LOGO_SMALL = LOGO_SMALL;
    this.MENU = MENU;
    this.SIGNIN = STAY_SIGNIN;
    this.ICON_CLOSE = ICON_CLOSE;
    this.ICON_CHEVRON_RIGHT = ICON_CHEVRON_RIGHT;
    this.ICON_MENU_BLACK = ICON_MENU_BLACK;
    this.ICON_MENU_WHITE = ICON_MENU_WHITE;
  },
  methods: {
    fetchNavigationItems() {
      butter.content
        .retrieve(['new_navigation_bar'], {
          locale: this.locale,
          preview: this.showPreview ? 1 : 0,
        })
        .then(response => {
          this.navigationItems = response.data.data.new_navigation_bar
            .sort((categoryA, categoryB) => {
              return categoryA.order - categoryB.order;
            })
            .map(item => {
              return {
                ...item,
                hasDynamicCategories: !item.linked_category_groups,
              };
            });
        })
        .catch(response => {
          console.log(response);
        });
    },
    setActiveNavMenu(menuProps) {
      Object.keys(this.$refs).forEach(ref => {
        if (ref.includes('menu-')) {
          this.$refs[ref][0].classList.remove('hovered');
        }
      });
      this.$refs[`menu-${menuProps.meta.id}`][0].classList.add('hovered');
      this.currentMenu = menuProps;
      this.showNavMenu = true;
    },
    openFullMenu() {
      this.showFullMenu = true;
    },
    closeFullMenu() {
      this.showFullMenu = false;
    },
    unselectMenu() {
      Object.keys(this.$refs).forEach(ref => {
        if (ref.includes('menu-')) {
          this.$refs[ref][0].classList.remove('hovered');
        }
      });
      this.showNavMenu = false;
    },
    onMenuButtonClick() {
      this.openFullMenu();
    },
    onShaderClick() {
      this.closeFullMenu();
    },
    openContactUs() {
      this.unselectMenu();
      this.closeFullMenu();
      this.$zendesk.open();
    },
  },
};
</script>

<style lang="scss">
#app.home {
  .navbar {
    background-color: transparent;
  }

  .solid {
    background-color: $bg-color;
  }
}
</style>

<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0.625rem;
}

.contact-us {
  color: $gray-700;
  font-size: 0.875rem;
  line-height: 1.225rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #0073ff;
  }
  &::after {
    @include right-arrow($primary);
    margin-left: 0;
    margin-right: 1.8rem;
    width: 0.7rem;
    height: 0.7rem;

    @include bp-small {
      margin-left: 0.625rem;
      margin-right: 0;
    }
  }
  @include bp-mediumsmall {
    font-size: 1.125rem;
    line-height: 1.4625rem;
  }
  @include bp-medium {
    font-size: 0.9375rem;
    line-height: 1.3125rem;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: 4;
  padding-top: 1rem;
  background-color: $bg-color;

  .links {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  @include bp-xsmall {
    padding: 1.25rem 1rem;
  }
  @include bp-small {
    padding: 1.25rem 1rem;
  }
  @include bp-medium {
    padding: 1rem 3rem;
  }
  @include bp-large {
    padding: 1rem $responsive-padding-desktop;
  }
}

.logo-container {
  img {
    width: auto;
    max-height: 2.125rem;
    display: block;

    @include bp-medium {
      max-height: 2.65rem;
    }
  }
}

.nav-menu {
  position: absolute;
  background: white;
  padding: 2.5rem 0 0;
  border-radius: 1.5rem;
  filter: drop-shadow(0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.25));
  min-width: 20rem;
  margin-left: $responsive-padding-desktop;
  margin-right: $responsive-padding-desktop;
  display: none;

  @include bp-mediumsmall() {
    top: 4.7rem;
    width: calc(100vw - 2rem);
    left: 0;
    right: 0;
    margin: auto;
    display: block;
  }

  @include bp-medium() {
    top: 4.5rem;
    left: auto;
    right: auto;
    width: auto;
    max-width: 24rem;
    max-width: 100%;
  }
}

.nav-items-container {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem;
  &.rows {
    flex-direction: row;
  }
}

.nav-item {
  width: 100%;
}

.nav-menu {
  .nav-item-container:not(:where(.nav-list-groups *)) {
    display: flex;
    align-items: center;
  }
}

.nav-list-groups {
  display: grid;
  grid-column-gap: 1.5rem;
  padding: 0 2rem;

  @include bp-medium() {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 2.5rem;
  }

  @include bp-large() {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 14.375rem);
    grid-column-gap: 3.5rem;
  }

  .nav-list-item {
    margin-top: 0;
    padding: 0;

    &::before {
      display: none;
    }

    @include bp-medium() {
      width: 16.875rem;
    }
  }

  ::v-deep .nav-item-description {
    margin: 0;
  }

  .nav-list-dummy {
    padding: 1rem 0 1rem 1rem;
  }
}

.nav-list-group {
  .title {
    margin: 0 0 1rem;
    padding-left: 1rem;
    color: $gray-500;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;

    @include bp-medium() {
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }

  &:nth-of-type(2) {
    margin-top: 3.5rem;
  }

  &:nth-of-type(3) {
    margin: 2.5rem 0;
  }

  @include bp-medium() {
    &:nth-of-type(1) {
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &:nth-of-type(2) {
      grid-column-start: 2;
      grid-row-start: 1;
      margin-top: 0;
    }

    &:nth-of-type(3) {
      margin-top: 2rem;
    }
  }

  @include bp-large() {
    &:nth-of-type(3) {
      margin-top: 0;
    }
  }
}

.nav-list {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.nav-list-item {
  display: none;
  position: relative;
  padding: 1rem 2rem 1rem 0;
  cursor: default;
  .nav-title {
    font-size: 1.125rem;
    line-height: 1.575rem;
  }
  h3 {
    margin-top: 0;
  }
  &.hovered {
    .nav-title {
      opacity: 0.6;
    }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      bottom: -0.15rem;
      left: 30%;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      z-index: 10;
    }
  }
  @include bp-mediumsmall {
    display: block;
  }
}

.nav-home {
  color: #ffffff;
}

.hamburguer {
  display: block;
  @include bp-mediumsmall {
    display: none;
  }
}

.nav-category-title {
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  color: $gray-500;
  font-size: 1.125rem;
  line-height: 1.4625rem;
  font-weight: 500;

  @include bp-small {
    font-size: 1.5rem;
    line-height: 1.95rem;
  }

  @include bp-medium {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}

.nav-item-container {
  padding: 1rem 0 1rem 1rem;
  border-radius: 0.5rem;
  &:hover {
    background: #e2efff;
  }
}

.fullmenu-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem 1rem;
  @include bp-mediumsmall {
    padding: 0 1rem 1rem;
  }
}

.products {
  width: 100%;
}

.product-groups {
  @include bp-medium {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .product-group {
    margin-bottom: 4rem;

    &:first-of-type {
      grid-row: 1 / 3;
    }

    &:nth-of-type(2) {
      grid-row: 2;
    }

    .title {
      margin: 0 0 1rem;
      padding-left: 1rem;
      color: $gray-500;
      font-size: 1.125rem;
      line-height: 1.4625rem;
      font-weight: 500;

      @include bp-mediumsmall {
        font-size: 1.5rem;
        line-height: 1.95rem;
      }

      @include bp-medium {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
  }

  .product-item {
    width: 100%;
    margin: 0;
    padding: 0;

    &::before {
      display: none;
    }
  }

  ::v-deep .nav-item-description {
    margin: 0.25rem 0 0;
  }

  .product-dummy {
    padding: 1rem 0 1rem 1rem;
  }
}

.fullmenu-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 2rem;
  @include bp-mediumsmall {
    width: 50%;
  }
  &.full {
    width: 100%;
    margin-bottom: 3.5rem;
    .fullmenu-links {
      display: flex;
      flex-wrap: wrap;
    }
    .fullmenu-link-item {
      width: 100%;
      @include bp-medium {
        width: 50%;
      }
    }
  }
}

.nav-footer {
  padding: 1.5rem 2rem 1.5rem 3rem;
  border-top: 0.5px solid #ced3dc;
  a {
    color: #293447;
  }
}

.fullmenu-footer {
  padding: 1.5rem;
  border-top: 0.5px solid #ced3dc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include bp-mediumsmall {
    padding: 1.5rem 2rem;
  }
  a {
    color: #293447;
  }
  .button-link {
    padding: 0.5rem;
    max-width: 50%;
    @include bp-mediumsmall {
      padding: 0.5rem 1rem;
    }
    .button-icon,
    ::v-deep .btn__content {
      font-size: 1rem;
      line-height: 1.175rem;
    }
    .button-icon {
      margin-left: 0.5rem;
    }
  }
}

.action-wrapper {
  display: flex;
  .button-link {
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
    max-width: 9.375rem;
    @include bp-mediumsmall {
      padding: 0.5rem 1rem;
    }
    &.nav-signin {
      display: none;
      color: #293447;
      width: 9.06rem;
      background-color: rgba(206, 211, 220, 0.2);
      border: none;
      justify-content: space-between;
      transition-duration: 0.2s;

      @include bp-medium {
        display: flex;
      }

      &:hover {
        background-color: rgba(206, 211, 220, 0.4);

        &:after {
          margin-right: 0;
        }
      }
      &:focus {
        background-color: rgba(206, 211, 220, 0.4);
        border: 1.5px solid #293447;
      }
      &:active {
        transform: scale(0.97);
      }

      &:after {
        @include right-arrow(#293447);
        margin-left: 0.3rem;
        margin-right: 0.18rem;
        height: 0.8rem;
        width: 0.8rem;
        vertical-align: middle;
      }

      &.test-result-home {
        display: none;
        color: #ffffff;
        background-color: rgba(256, 256, 256, 0.2);

        @include bp-medium {
          display: flex;
        }

        &:hover {
          background-color: rgba(256, 256, 256, 0.4);

          &:after {
            margin-right: 0;
          }
        }
        &:focus {
          background-color: rgba(256, 256, 256, 0.4);
          border: 1.5px solid #ffffff;
        }

        &:after {
          @include right-arrow(#ffffff);
        }
      }
    }
    &:first-child:not(:last-child) {
      margin-right: 1rem;
    }
    .button-icon {
      margin-left: 0.5rem;
    }
  }
}

.nav-full-menu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 1rem;
  margin-top: var(--offsetDueToAndroidSmartBanner);
  background: white;
  border-radius: 1rem;
  overflow: auto;
  z-index: 9;
  filter: drop-shadow(0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.25));
  .nav-footer {
    padding: 1.5rem 2rem;
  }
}

.close-full-menu {
  display: block;
  text-align: right;
  margin: 1.5rem 1.5rem 0 0;
}

.shader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 0;
  background-color: $white;
  transition: opacity $animation-interval ease-in-out;

  &.active {
    display: block;
    opacity: 0.67;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
