import './styles/global/base.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from "./i18n";
import './external.js';
import Zendesk from '@dansmaculotte/vue-zendesk';

// https://github.com/dansmaculotte/vue-zendesk
Vue.use(Zendesk, {
  key: '7211cd5f-eeae-4e6e-ae93-20d9007ad2b3',
  settings: {
    webWidget: {
      launcher: {
        label: {
          '*': 'Contact Us',
        },
      },
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  created() {
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute("lang", i18n.locale);
  },
  render: h => h(App),
}).$mount('#app');
