<template>
  <div :class="contentClasses"  id="content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Content',
  computed: {
    contentClasses() {
      return [
        'content',
        this.$route.name ? this.$route.name.toLowerCase() : ''
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  img {
    max-width: 100%;
    max-height: 100%;
  }
  @include bp-xsmall {
    padding: 1.25rem 1rem;
  }
  @include bp-small {
    padding: 1.25rem 1rem;
  }
  @include bp-medium {
    padding: 1rem 3rem;
  }
  @include bp-large {
    padding: 1rem 5rem;
  }
}
</style>
