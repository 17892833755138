<template>
  <div class="footer" id="footer">
    <div class="main">
      <router-link-escape to="home">
        <img
          :src="LOGO_TIGHT"
          width="160"
          height="56"
          alt="Healthy Together"
          class="logo"
        />
      </router-link-escape>
      <nav class="footer-groups">
        <div
          v-for="(footerGroup, index) in footerNavigationItems"
          :key="index"
          class="footer-group"
        >
          <h3 class="footer-group-label">{{ footerGroup.label }}</h3>
          <ul v-if="locale">
            <li
              v-for="(item, index) in footerGroup.items"
              :key="index"
              class="label"
            >
              <router-link v-if="item.route" :to="item.route" class="content">
                {{ item.name }}
              </router-link>
              <span v-else class="content">
                {{ item.name }}
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="tail">
      <span class="content">
        Get the app
      </span>
      <div class="appendix">
        <div class="mobile">
          <AppStoreLinks />
        </div>
        <div class="rights">
          {{ $t('footerCopyright', thisYear) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import RouterLinkEscape from '@/components/structure/RouterLinkEscape';
import { ICON_CHEVRON, LOGO_TIGHT } from '@/constants/cloudinary';
import AppStoreLinks from '@/components/basic/AppStoreLinks.vue';

export default {
  name: 'Footer',
  components: {
    RouterLinkEscape,
    AppStoreLinks,
  },
  computed: {
    ...mapState(['footerNavigationItems']),
    locale() {
      return this.$route.params.locale;
    },
    thisYear() {
      return {
        year: new Date().getFullYear(),
      };
    },
  },
  created() {
    this.LOGO_TIGHT = LOGO_TIGHT;
    this.ICON_CHEVRON = ICON_CHEVRON;
  },
};
</script>

<style scoped lang="scss">
.footer {
  min-height: 73.25rem;
  background-color: $gray-0;
  padding: 2rem 1rem;
  color: $plain-text;

  li {
    display: list-item;
    text-decoration: none;

    &::before {
      display: none;
    }
  }

  .main {
    display: flex;
    flex-direction: column;

    .footer-groups {
      margin-top: 2.5rem;
    }

    .footer-groups-features {
      margin-top: 3rem;
    }

    .footer-group:not(:last-of-type) {
      margin-bottom: 3rem;
    }

    .footer-group-label {
      margin-bottom: 2rem;
      color: $gray-700;
      font-size: 1rem;
      line-height: 140%;
      font-weight: 500;
    }

    .label {
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
    }

    .content {
      display: flex;
      align-items: center;
      color: $gray-600;
      font-weight: 400;
      line-height: 140%;
    }

    .accent {
      margin-right: 0.5rem;
    }
  }

  .logo {
    width: 8.125rem;
    height: 2.84375rem;
  }

  .main .content,
  .content {
    font-size: 0.875rem;
  }

  .tail {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    .content {
      font-size: 1rem;
      font-weight: 500;
      line-height: 140%;
      color: $gray-700;
    }
  }

  .appendix {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 2rem 0;
  }

  .rights {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 140%;
    color: $gray-600;
    margin-top: 1rem;
  }

  @include bp-mediumsmall {
    min-height: 69.75rem;
    padding: 3rem 1.5rem;

    .main {
      .footer-groups {
        display: grid;
        grid-template-rows: repeat(3, auto);
        grid-auto-flow: column;
      }
    }

    .tail {
      margin-top: 2rem;
    }
  }

  @include bp-medium {
    min-height: 55.25rem;
    padding: 2.5rem 1.5rem;

    .main .content,
    .content {
      font-size: 1rem;
    }

    .main {
      .footer-groups {
        display: grid;
        grid-template-columns: repeat(2, 1.5fr) 0.9fr;
        grid-template-rows: none;
        grid-auto-flow: row;
      }

      .footer-group-label {
        font-size: 1.125rem;
      }
    }

    .tail {
      .content {
        font-size: 1.125rem;
      }
    }

    .appendix {
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  @include bp-large {
    min-height: 34rem;
    padding: 4.5rem 4rem;

    .main {
      .footer-groups {
        display: flex;

        > :not(:last-child) {
          margin: 0 4.7rem 0 0;
        }
      }
    }

    .logo {
      width: 10rem;
      height: 3.5rem;
    }
  }

  @include bp-extralarge {
    margin: 0 auto;
  }

  .mobile {
    display: flex;
    width: auto;

    .stores {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
