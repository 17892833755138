<template>
  <div id="app" :class="appClasses">
    <AndroidSmartBanner
      v-if="isAndroidSmartBannerVisible"
      @close="closeAndroidSmartBanner"
      appId='co.twenty.stop.spread'
    />
    <navbar 
      @side-menu-shown="toggleScrolling" 
      :class="navBarClasses"
      :isAndroidSmartBannerVisible="isAndroidSmartBannerVisible" 
    />
    <Content></Content>
    <Footer v-if="!isCurrentRouteWhitelisted"></Footer>
    <Indicator
      v-if="showIndicator"
      :latestCommit="shaVersion"
      :latestVersion="appVersion" />
  </div>
</template>

<script>
import { throttle } from 'underscore';
import { mapState } from 'vuex';

import AndroidSmartBanner from '@/components/structure/AndroidSmartBanner.vue';
import Navbar from '@/components/structure/Navbar.vue';
import Content from '@/components/structure/Content.vue';
import Footer from '@/components/structure/Footer.vue';
import Indicator from '@/components/utils/Indicator.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Content,
    Footer,
    Indicator,
    AndroidSmartBanner,
  },
  data() {
    return {
      showShadow: false,
      solidBg: false,
      scrollContainer: null,
      isScrollingLocked: false,
      childTriggeredClasses: [],
      shaVersion: require('./appDetails.json').shaVersion,
      appVersion: require('./appDetails.json').appVersion,
      test: navigator.userAgent,
      isAndroidSmartBannerVisible: this.isAndroid(),
    };
  },
  computed: {
    ...mapState(['showPreview']),
    whiteListedRoutes() {
      return [
        'Blog',
      ];
    },
    currentRouteName() {
      return this.$route.name;
    },
    isCurrentRouteWhitelisted() {
      return this.whiteListedRoutes.includes(this.currentRouteName);
    },
    appClasses() {
      return [ this.isScrollingLocked ? 'locked' : '', ...this.childTriggeredClasses ];
    },
    navBarClasses() {
      return [{'shadow': this.showShadow}, {'solid': this.solidBg}];
    },
    locale() {
      return this.$route.params.locale;
    },
  },
  created() {
    this.throttledOnScroll = throttle(this.onScroll, 16);
    this.showIndicator = process.env.VUE_APP_SHOW_INDICATOR;
  },
  mounted() {
    this.scrollContainer = document.querySelector('html');
    window.addEventListener('scroll', this.throttledOnScroll);
    this.$root.$on('add-class-to-app', this.addAppClasses);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledOnScroll);
  },
  watch: {
    locale() {
      this.loadNavigationData();
    },
  },
  methods: {
    onScroll() {
      this.showShadow = !!this.scrollContainer.scrollTop;
      this.solidBg = !!this.scrollContainer.scrollTop;
    },
    toggleScrolling(shouldLock) {
      this.isScrollingLocked = shouldLock;
    },
    addAppClasses(customClassesArr = []) {
      this.childTriggeredClasses = customClassesArr;
    },
    loadNavigationData() {
      const config = {
        locale: this.locale,
        showPreview: this.showPreview,
      };
      this.$store.dispatch('getNavigationProductGroups', {...config, levels: 2});
      this.$store.dispatch('getFooterNavigationItems', {...config, levels: 3});
    },
    isAndroid() {
      const response = /(Android)/i.test(navigator.userAgent);

      if (!response) {
        this.closeAndroidSmartBanner();
      }

      return response;
    },
    closeAndroidSmartBanner() {
      this.isAndroidSmartBannerVisible = false;
      this.$store.dispatch('closeAndroidSmartBanner');
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: $bg-color;

  &.locked {
    height: 100vh;
    overflow: hidden;
  }
}

.content {
  min-height: 50rem;
}

.shadow {
  box-shadow:0 2px 4px rgba(8, 35, 48, 0.1);
}

@include bp-large {
  .navbar,
  .content,
  .footer {
    padding-left: $responsive-padding;
    padding-right: $responsive-padding;
    @include bp-large {
      padding-left: $responsive-padding-desktop;
      padding-right: $responsive-padding-desktop;
    }
  }
}
</style>
