const ENGLISH = {
  code: 'en',
  nameKey: 'localeLanguageEnglish',
};

const locales = [
  ENGLISH,
];

const defaultLocale = ENGLISH;

export default {
  defaultLocale,
  locales,
};

export {
  defaultLocale,
  locales
};
