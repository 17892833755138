<template>
  <component
    v-bind="options"
    :is="tag"
    :class="[
      'btn',
      {
        'btn-primary': color === 'primary',
        'btn-secondary': color === 'secondary',
        'btn-default': color === 'default',
      },
      customClass,
    ]"
    @click="call"
  >
    <span class="btn__content">
      {{ text }}
    </span>
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    href: {
      type: [String, Object],
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      if (this.href) {
        if (this.isExternalLink) {
          return 'a';
        }
        return 'router-link';
      }
      return 'button';
    },
    options() {
      if (this.tag === 'a') {
        return { href: this.href, target: this.target };
      }
      if (this.tag === 'router-link') {
        return {
          to: this.href.name ? this.href : { path: this.localizedPath(this.href) },
          activeClass: '',
          exactActiveClass: '',
          target: this.target,
        };
      }
      return {};
    },
    isExternalLink() {
      if (this.href.name) {
        return false;
      }
      return this.href.match(/(^https?:)|(^mailto:)/);
    },
  },
  methods: {
    call() {
      this.$emit('btn-click');
    },
    localizedPath(p) {
      return p === 'home'
        ? `/${this.$route.params.locale}`
        : `/${this.$route.params.locale}/${p}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  display: inline-block;
}
.action-link {
  color: $primary;
}
.action-link-renewed {
  color: $primary;
  margin-top: 1.5rem;

  &::after {
    @include filled-right-arrow($primary);
    width: 1.0625rem;
    height: 1.0625rem;
    margin-bottom: 0.125rem;
  }

  &.btn-primary {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  &.btn-secondary {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  .btn__content {
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 400;

    @include bp-xlarge {
      font-size: 1.375rem;
      line-height: 1.4375rem;
    }
  }

  @include bp-xlarge {
    margin-top: 2.5rem;
  }
}
.button-link {
  color: $white;
  padding: 0.75rem 1.5rem;
  @include bp-mediumsmall {
    width: auto;
  }
  width: 100%;
  text-align: center;
  border-radius: 6.25rem;
  transition: all 240ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  &.btn-primary {
    background-color: $primary;
    &:hover {
      background-color: #0053b9;
    }
  }
  &.btn-secondary {
    background-color: $secondary;
    &:hover {
      background-color: #13145c;
    }
  }
  &.btn-default {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    &:hover {
      background-color: transparent;
    }
  }
  &.small {
    .btn__content {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.4rem;

      @include bp-large {
        font-size: 1.375rem;
        line-height: 1.788rem;
      }
    }
  }
}

.btn__content {
  font-size: 0.9375rem;
  font-weight: 700;
}
</style>
