import Vue from 'vue';
import VueRouter from 'vue-router';

import i18n from '@/i18n';
import { defaultLocale, locales } from '@/constants/locales';

Vue.use(VueRouter);

const supportedLocaleCodes = locales.map(locale => locale.code);
const defaultLocalePath = `/${defaultLocale.code}/`;

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: 'government',
    name: 'government',
    component: () =>
      import(/* webpackChunkName: "government" */ '../views/Government.vue'),
    redirect: to => {
      return {
        name: 'product',
        params: { locale: to.params.locale, slug: 'epi-management' },
      };
    },
  },
  {
    path: 'education',
    name: 'education',
    component: () =>
      import(/* webpackChunkName: "education" */ '../views/Education.vue'),
    redirect: to => {
      return {
        name: 'product',
        params: { locale: to.params.locale, slug: 'campus-health-pass' },
      };
    },
  },
  {
    path: 'enterprise',
    name: 'enterprise',
    component: () =>
      import(/* webpackChunkName: "enterprise" */ '../views/Enterprise.vue'),
    redirect: to => {
      return {
        name: 'product',
        params: { locale: to.params.locale, slug: 'employee-health-pass' },
      };
    },
  },
  {
    path: 'business',
    name: 'business',
    component: () =>
      import(/* webpackChunkName: "business" */ '../views/Business.vue'),
    redirect: to => {
      return {
        name: 'product',
        params: { locale: to.params.locale, slug: 'employee-health-pass' },
      };
    },
  },
  {
    path: 'blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
  },
  {
    path: 'post/:slug',
    name: 'blog-post',
    component: () =>
      import(/* webpackChunkName: "blog-post" */ '../views/BlogPost.vue'),
  },
  {
    path: 'jobs',
    name: 'jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue'),
  },
  {
    path: 'jobs/:slug',
    name: 'job',
    component: () => import(/* webpackChunkName: "job" */ '../views/Job.vue'),
  },
  {
    path: 'contact-us',
    name: 'contact-us',
    component: () =>
      import(/* webpackChunkName: "contact-us" */ '../views/ContactUs.vue'),
  },
  {
    path: 'help',
    name: 'help-center',
    component: () =>
      import(/* webpackChunkName: "help-center" */ '../views/HelpCenter.vue'),
  },
  {
    path: 'help/:slug',
    name: 'help-topic',
    component: () =>
      import(/* webpackChunkName: "help-topic" */ '../views/HelpTopic.vue'),
  },
  {
    path: 'security',
    name: 'security',
    component: () =>
      import(/* webpackChunkName: "security" */ '../views/Security.vue'),
  },
  {
    path: 'legal/:slug',
    name: 'legal',
    component: () =>
      import(/* webpackChunkName: "legal" */ '../views/Legal.vue'),
  },
  {
    path: 'press',
    name: 'press',
    component: () =>
      import(/* webpackChunkName: "press" */ '../views/Press.vue'),
  },
  {
    path: 'product/:slug',
    name: 'product',
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/Products.vue'),
  },
  {
    path: 'app',
    name: 'app',
    component: () =>
      import(/* webpackChunkName: "mobile" */ '../views/Mobile.vue'),
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      return next(`/${to.params.locale}`);
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      redirect: defaultLocalePath,
    },
    {
      path: '/:locale',
      component: () =>
        import(/* webpackChunkName: "base" */ '../LocaleBase.vue'),
      beforeEnter: (to, from, next) => {
        const locale = to.params.locale;

        // Maintain backwards compatibility with old site links
        // healthytogether.io/blog => healthytogether.io/en/blog
        if (locale && locale.length > 2) {
          const noLocaleRoute = routes.find(route => route.name === locale);
          const redirect = noLocaleRoute
            ? {
                name: noLocaleRoute.name,
                params: { locale: defaultLocale.code },
              }
            : defaultLocalePath;
          return next(redirect);
        }

        if (!supportedLocaleCodes.includes(locale)) {
          return next(defaultLocalePath);
        }
        if (i18n.locale !== locale) {
          i18n.locale = locale;
        }
        return next();
      },
      children: routes,
    },
    {
      path: '*',
      redirect: defaultLocalePath,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  //Redirect from .io to .co domain
  if (window.location.host === 'www.healthytogether.io') {
    window.location.href = `https://www.healthytogether.co${to.fullPath}`;
  }
  next();
});

export default router;
