var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"component",class:[
    'btn',
    {
      'btn-primary': _vm.color === 'primary',
      'btn-secondary': _vm.color === 'secondary',
      'btn-default': _vm.color === 'default',
    },
    _vm.customClass ],on:{"click":_vm.call}},'component',_vm.options,false),[_c('span',{staticClass:"btn__content"},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }