const getPath = function(navItem) {
  // filter out empty element
  let pathArr = navItem.destination.split('/').filter(param => param.length);

  if (pathArr.length === 1) {
    return { name: navItem.destination };
  }

  return {
    name: pathArr.shift(),
    params: { slug: pathArr.shift() },
  };
};

export default getPath;
