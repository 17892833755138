import Vue from 'vue';
import Vuex from 'vuex';

import { butter } from '@/buttercms';
import getPath from '@/navpaths.js';

function fetchButterContent(keys, { locale, showPreview, levels }) {
  return butter.content.retrieve(
    keys,
    {
      locale: locale,
      preview: showPreview ? 1 : 0,
      levels: levels,
    }
  );
}

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    navigationProductGroups: [],
    footerNavigationItems: [],
    showPreview: process.env.VUE_APP_BUTTERCMS_PREVIEW,
    isAndroidSmartBannerVisible: true,
  },
  mutations: {
    setNavigationProductGroups(state, navigationProductGroups) {
      state.navigationProductGroups = navigationProductGroups;
    },
    setFooterNavigationItems(state, footerNavigationItems) {
      state.footerNavigationItems = footerNavigationItems;
    },
    setCloseAndroidSmartBanner(state) {
      state.isAndroidSmartBannerVisible = false;
    }
  },
  actions: {
    getNavigationProductGroups({ commit }, config) {
      fetchButterContent(['navigation_product_group'], config)
        .then((response) => {
          const productGroups = response.data.data.navigation_product_group
            .sort((categoryA, categoryB) => {
              return categoryA.order - categoryB.order;
            })
            .map((group) => {
              const formattedItems = group.product_items.map(product => {
                const route = product.route
                  ? {
                    label: product.name,
                    name: product.route,
                    params: { slug: product.slug },
                    description: product.description,
                  }
                  : null;
                return {
                  ...product,
                  route: route,
                };
              });
              return {
                ...group,
                product_items: formattedItems,
              };
            });
          commit('setNavigationProductGroups', productGroups);
        })
        .catch((error) => {
          console.log(error);
        }
      );
    },
    getFooterNavigationItems({ commit }, config) {
      fetchButterContent(['new_footer_navigation'], config)
        .then((response) => {
          const footerNavigationItems = response.data.data.new_footer_navigation
            .sort((footerGroupA, footerGroupB) => {
              return footerGroupA.order - footerGroupB.order;
            })
            .map((footer) => {
              let footerGroupLabel = "";
              let footerGroupItems = [];
              
              if (footer.navigation_product_group.length) {
                footer.navigation_product_group.map((footerGroup, index) => {
                  footerGroupLabel += index === 0 ? footerGroup.name : " & " + footerGroup.name;
                  footerGroupItems = footerGroupItems.concat(footerGroup.product_items.map((footerGroupItem) => {
                    return ({
                      name: footerGroupItem.name,
                      route: {
                        label: footerGroupItem.name,
                        name: footerGroupItem.route,
                        params: { slug: footerGroupItem.slug },
                        description: footerGroupItem.description,
                      },
                    }); 
                  }));
                });
              } else if (footer.navigation_item.length) {
                footer.navigation_item.map((footerGroup, index) => {
                  footerGroupLabel += index === 0 ? footerGroup.name : " & " + footerGroup.name;
                  footerGroupItems = footerGroupItems.concat(footerGroup.navigation_items.map((footerGroupItem) => {
                    return ({
                      name: footerGroupItem.label,
                      route: getPath(footerGroupItem),
                    }); 
                  }));
                });
              }

              return ({
                label: footerGroupLabel,
                items: footerGroupItems,
              });
            });
          commit('setFooterNavigationItems', footerNavigationItems);
        })
        .catch((error) => {
          console.log(error);
        }
      );
    },
    closeAndroidSmartBanner({ commit }) {
      commit('setCloseAndroidSmartBanner');
    }
  },
});

export default store;
