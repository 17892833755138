<template>
  <component :is="tag" v-bind="options">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    escape: {
      type: Boolean,
      default: false,
    },
    to: {
      // Use if linking to a named route
      type: [String, Object],
      default: '',
    },
    path: {
      // Use the exact path if not linking to a named route
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      if (this.escapeTheRouter) {
        return 'a';
      }
      return 'router-link';
    },
    options() {
      if (this.tag === 'a') {
        return { href: this.localizedPath(this.to || this.path) };
      }
      if (this.tag === 'router-link') {
        return {
          to: this.to
            ? { name: this.to }
            : { path: this.localizedPath(this.path) },
        };
      }
      return {};
    },
    escapeTheRouter() {
      return this.to
        ? this.$route.name === this.to
        : this.$route.path === this.localizedPath(this.path);
    },
  },
  methods: {
    localizedPath(p) {
      return p === 'home'
        ? `/${this.$route.params.locale}/`
        : `/${this.$route.params.locale}/${p}`;
    },
  },
};
</script>
