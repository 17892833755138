const TRANSFORMS = 'f_auto,q_auto';
const APP_STORE_DARK =
  'https://res.cloudinary.com/healthytogether/image/upload/v1618434351/htio/app-store_btn.svg';
const APP_STORE_LIGHT =
  'https://res.cloudinary.com/healthytogether/image/upload/v1618434351/htio/app-store-light_btn.svg';
const PLAY_STORE_DARK =
  'https://res.cloudinary.com/healthytogether/image/upload/v1618434351/htio/google-play_btn.svg';
const PLAY_STORE_LIGHT =
  'https://res.cloudinary.com/healthytogether/image/upload/v1618434351/htio/google-play-light_btn.svg';
const MOBILE = `https://res.cloudinary.com/healthytogether/image/upload/${TRANSFORMS}/htio/download_app.png`;

const LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1617118878/htio/logo.svg';
const LOGO_LARGE =
  'https://res.cloudinary.com/healthytogether/image/upload/v1617118877/htio/logo-large.svg';
const LOGO_HORIZONTAL =
  'https://res.cloudinary.com/healthytogether/image/upload/v1633537923/htio/ht-logo-h-hq.png';
const LOGO_SMALL =
  'https://res.cloudinary.com/healthytogether/image/upload/v1626365702/k-12/email/ht-logo-small.svg';
const LOGO_TIGHT =
  'https://res.cloudinary.com/healthytogether/image/upload/v1634686454/htio/logo-tight.png';
const LOGO_TIGHT_WHITE =
  'https://res.cloudinary.com/healthytogether/image/upload/v1645432436/htio/HT-logo-tight-white.png';

const MENU =
  'https://res.cloudinary.com/healthytogether/image/upload/v1618604442/htio/menu.svg';
const BUSINESS_AD =
  'https://res.cloudinary.com/healthytogether/image/upload/v1618344100/htio/5f73ae70d227dbe2d8b0148d_business-free-ad-img.png';
const BUSINESS_AD_MOBILE =
  'https://res.cloudinary.com/healthytogether/image/upload/v1618509407/htio/5f73ae811efdaa6cd20c56ad_business-free-ad-mobile-img.png';
const GREEN_CHECK =
  'https://res.cloudinary.com/healthytogether/image/upload/v1619204173/htio/white-on-green-check-32.png';
const RIGHT_ARROW =
  'https://res.cloudinary.com/healthytogether/image/upload/htio/right-arrow.svg';
const SUCCESS_CHECK =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620163972/htio/contact-us-success.svg';

const VIDEO_ICON_PLACEHOLDER =
  'https://res.cloudinary.com/healthytogether/image/upload/v1619453139/htio/5ea5aa5e808bdf369d927bd7_play-btn.svg';
const DOWNLOAD_ICON =
  'https://res.cloudinary.com/healthytogether/image/upload/v1619480822/htio/5ea6505527484b8e86b2cb9f_press-kit-dl-icon_1.svg';

const GOVERNMENT_ILLUSTRATION =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620241636/htio/solutions/608b196fdf3a77fec87f359e_Government_Hero_Illustration.png';
const GOVERNMENT_TEST_RESULTS =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379114/htio/solutions/mobile_test_result_2x.png';
const GOVERNMENT_CONTACT_TRACING =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379113/htio/solutions/immune_records_2x.png';
const GOVERNMENT_IMMUNIZATION_RECORDS =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379113/htio/solutions/inapp_contact-tracing_2x.png';
const GOVERNMENT_VACCINE_APPOINTMENT =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379114/htio/solutions/vaccine_appt_scheduling_2x.png';
const GOVERNMENT_RESIDENT_COMMS =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624342103/htio/solutions/comm_engagement_2x.png';
const GOVERNMENT_CASE_MANAGEMENT =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620242082/htio/solutions/case-management-img-p-800.png';
const GOVERNMENT_IMPACT_ILLUSTRATION =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620242081/htio/solutions/impact_illustration-p-500.png';
const GOVERNMENT_QUOTE_ICON =
  'https://res.cloudinary.com/healthytogether/image/upload/v1621044210/htio/solutions/5ea0725bf301fb567c45d6c8_quote_icon.svg';
const GOVERNMENT_SEAL_OF_UTAH =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620242081/htio/solutions/Seal_of_Utah.svg';
const GOVERNMENT_GOVT_SIGNED =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620242081/htio/solutions/govt-signed.svg';
const CHECK_ICON =
  'https://res.cloudinary.com/healthytogether/image/upload/v1621279032/htio/solutions/5f4d2bb435cc252c12fc3fbc_Check_Icon.svg';
const EDUCATION_ILLUSTRATION =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620251371/htio/solutions/Education_Hero_Illustration.png';
const EDUCATION_TEST_ADMINISTRATION =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379114/htio/solutions/test_admin_2x.png';
const EDUCATION_DATA_REPORTING =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379113/htio/solutions/data_reporting_2x.png';
const EDUCATION_PATIENT_EXPERIENCE =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379114/htio/solutions/patient_experience_2x.png';
const EDUCATION_DAILY_PASSPORT =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624342031/htio/solutions/daily_passport_school_2x.png';
const EDUCATION_TODAY_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620250701/htio/solutions/today_logo.png';
const EDUCATION_CNN_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620250701/htio/solutions/Ellipse_140.png';
const EDUCATION_CNBC_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620250701/htio/solutions/Ellipse_138.png';
const EDUCATION_WSJ_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620250701/htio/solutions/Ellipse_139.png';

const ENTERPRISE_ILLUSTRATION =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620251154/htio/solutions/Business_Hero_Illustration.png';
const ENTERPRISE_EMPLOYEE_CHECKUPS =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379113/htio/solutions/employee_checkup_2x.png';
const ENTERPRISE_EDUCATE_TEAM =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379113/htio/solutions/educate_team_2x.png';
const ENTERPRISE_HOME_FREE_CONTENT =
  'https://res.cloudinary.com/healthytogether/image/upload/v1620251154/htio/solutions/home-free-content-img-p-800.png';
const ENTERPRISE_DAILY_PASSPORT =
  'https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/v1624379113/htio/solutions/daily_passport_work_2x.png';
const BUSINESS_TRAEGER = `https://res.cloudinary.com/healthytogether/image/upload/${TRANSFORMS}/htio/solutions/traeger-sp-title.png`;
const BUSINESS_TRUST_HELP =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/trust-help-img-p-500.png';
const BUSINESS_HILTON_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/hilton-logo.png';
const BUSINESS_MINESSOTA_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/minnesota-chamber-logo.png';
const BUSINESS_TRUST_SIMPLE =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/trust-simple-img-p-500.png';
const BUSINESS_TRUST_KEEP =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/trust-keep-img-p-500.png';
const BUSINESS_ACME_DASHBOARD =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/ACME-Dashboard.png';
const BUSINESS_FL_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/fl-logo.png';
const BUSINESS_IMAGE_4 =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/image_4.png';
const BUSINESS_RSL_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/rsl_logo.png';
const BUSINESS_VIDANGEL_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/VidAngel_Logo.png';
const BUSINESS_BYUSP_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/BYU-sp-logo.png';
const BUSINESS_SLCCHAMBERS_LOGO =
  'https://res.cloudinary.com/healthytogether/image/upload/v1624907332/htio/solutions/slc-chambers-logo.png';
const BUSINESS_PASSPORT_ASSESSMENT =
  'https://res.cloudinary.com/healthytogether/image/upload/v1625001551/htio/solutions/passport-assessment.png';
const BUSINESS_HEALTH_STANDARD =
  'https://res.cloudinary.com/healthytogether/image/upload/v1625001550/htio/solutions/health-standard.svg';
const BUSINESS_STATUSCLEAR =
  'https://res.cloudinary.com/healthytogether/image/upload/v1625001551/htio/solutions/statusClear.svg';
const BUSINESS_CD_FREE =
  'https://res.cloudinary.com/healthytogether/image/upload/v1625001550/htio/solutions/cd_free.svg';
const BUSINESS_PASSPORT_CLEAR =
  'https://res.cloudinary.com/healthytogether/image/upload/v1626110803/htio/solutions/passport-clear.png';
const BUSINESS_PASSPORT_NOCLEAR =
  'https://res.cloudinary.com/healthytogether/image/upload/v1626110803/htio/solutions/passport-norclear.png';

const HOME_HEALTH_N_SAFETY_DESKTOP = `https://res.cloudinary.com/healthytogether/image/upload/${TRANSFORMS}/v1624744765/htio/hero-desktop_2x.png`;
const HOME_HEALTH_N_SAFETY_MOBILE = `https://res.cloudinary.com/healthytogether/image/upload/${TRANSFORMS}/v1624744765/htio/hero-mobile_2x.png`;
const HOME_PRIVACY = `https://res.cloudinary.com/healthytogether/image/upload/v1625088761/htio/privacy.svg`;
const HOME_SOC2 = `https://res.cloudinary.com/healthytogether/image/upload/${TRANSFORMS}/v1624744766/htio/soc2-compliant-type1_2x.png`;
const HOME_HIPAA = `https://res.cloudinary.com/healthytogether/image/upload/${TRANSFORMS}/v1624744765/htio/hipaa-compliant_2x.png`;
const HOME_BENCHMARKS = `https://res.cloudinary.com/healthytogether/image/upload/${TRANSFORMS}/v1624744765/htio/cis-benchmarks-certified_2x.png`;

const ICON_CHEVRON =
  'https://res.cloudinary.com/healthytogether/image/upload/htio/link-chevron.svg';
const PRODUCT_PAGE_ICON_FOLDER =
  'https://res.cloudinary.com/healthytogether/image/upload/v1653495904/htio/product-pages/navigation-icons';

export default {
  APP_STORE_DARK,
  APP_STORE_LIGHT,
  PLAY_STORE_DARK,
  PLAY_STORE_LIGHT,
  LOGO,
  LOGO_LARGE,
  LOGO_HORIZONTAL,
  LOGO_SMALL,
  LOGO_TIGHT_WHITE,
  MENU,
  MOBILE,
  BUSINESS_AD,
  BUSINESS_AD_MOBILE,
  GREEN_CHECK,
  RIGHT_ARROW,
  SUCCESS_CHECK,
  VIDEO_ICON_PLACEHOLDER,
  DOWNLOAD_ICON,
  ICON_CHEVRON,
  GOVERNMENT_ILLUSTRATION,
  GOVERNMENT_TEST_RESULTS,
  GOVERNMENT_CONTACT_TRACING,
  GOVERNMENT_IMMUNIZATION_RECORDS,
  GOVERNMENT_VACCINE_APPOINTMENT,
  GOVERNMENT_RESIDENT_COMMS,
  GOVERNMENT_CASE_MANAGEMENT,
  GOVERNMENT_IMPACT_ILLUSTRATION,
  GOVERNMENT_QUOTE_ICON,
  GOVERNMENT_SEAL_OF_UTAH,
  GOVERNMENT_GOVT_SIGNED,
  CHECK_ICON,
  EDUCATION_ILLUSTRATION,
  EDUCATION_TEST_ADMINISTRATION,
  EDUCATION_DATA_REPORTING,
  EDUCATION_PATIENT_EXPERIENCE,
  EDUCATION_DAILY_PASSPORT,
  EDUCATION_TODAY_LOGO,
  EDUCATION_CNN_LOGO,
  EDUCATION_CNBC_LOGO,
  EDUCATION_WSJ_LOGO,
  ENTERPRISE_ILLUSTRATION,
  ENTERPRISE_EMPLOYEE_CHECKUPS,
  ENTERPRISE_EDUCATE_TEAM,
  ENTERPRISE_HOME_FREE_CONTENT,
  ENTERPRISE_DAILY_PASSPORT,
  BUSINESS_TRAEGER,
  BUSINESS_TRUST_HELP,
  BUSINESS_HILTON_LOGO,
  BUSINESS_MINESSOTA_LOGO,
  BUSINESS_TRUST_SIMPLE,
  BUSINESS_TRUST_KEEP,
  BUSINESS_ACME_DASHBOARD,
  BUSINESS_FL_LOGO,
  BUSINESS_IMAGE_4,
  BUSINESS_RSL_LOGO,
  BUSINESS_VIDANGEL_LOGO,
  BUSINESS_BYUSP_LOGO,
  BUSINESS_SLCCHAMBERS_LOGO,
  BUSINESS_PASSPORT_ASSESSMENT,
  BUSINESS_STATUSCLEAR,
  BUSINESS_HEALTH_STANDARD,
  BUSINESS_CD_FREE,
  HOME_HEALTH_N_SAFETY_DESKTOP,
  HOME_HEALTH_N_SAFETY_MOBILE,
  HOME_PRIVACY,
  HOME_SOC2,
  HOME_HIPAA,
  HOME_BENCHMARKS,
  BUSINESS_PASSPORT_CLEAR,
  BUSINESS_PASSPORT_NOCLEAR,
  PRODUCT_PAGE_ICON_FOLDER,
};

export {
  APP_STORE_DARK,
  APP_STORE_LIGHT,
  PLAY_STORE_DARK,
  PLAY_STORE_LIGHT,
  LOGO,
  LOGO_LARGE,
  LOGO_HORIZONTAL,
  LOGO_SMALL,
  LOGO_TIGHT,
  LOGO_TIGHT_WHITE,
  MENU,
  MOBILE,
  BUSINESS_AD,
  BUSINESS_AD_MOBILE,
  GREEN_CHECK,
  RIGHT_ARROW,
  SUCCESS_CHECK,
  VIDEO_ICON_PLACEHOLDER,
  DOWNLOAD_ICON,
  ICON_CHEVRON,
  GOVERNMENT_ILLUSTRATION,
  GOVERNMENT_TEST_RESULTS,
  GOVERNMENT_CONTACT_TRACING,
  GOVERNMENT_IMMUNIZATION_RECORDS,
  GOVERNMENT_VACCINE_APPOINTMENT,
  GOVERNMENT_RESIDENT_COMMS,
  GOVERNMENT_CASE_MANAGEMENT,
  GOVERNMENT_IMPACT_ILLUSTRATION,
  GOVERNMENT_QUOTE_ICON,
  GOVERNMENT_SEAL_OF_UTAH,
  GOVERNMENT_GOVT_SIGNED,
  CHECK_ICON,
  EDUCATION_ILLUSTRATION,
  EDUCATION_TEST_ADMINISTRATION,
  EDUCATION_DATA_REPORTING,
  EDUCATION_PATIENT_EXPERIENCE,
  EDUCATION_DAILY_PASSPORT,
  EDUCATION_TODAY_LOGO,
  EDUCATION_CNN_LOGO,
  EDUCATION_CNBC_LOGO,
  EDUCATION_WSJ_LOGO,
  ENTERPRISE_ILLUSTRATION,
  ENTERPRISE_EMPLOYEE_CHECKUPS,
  ENTERPRISE_EDUCATE_TEAM,
  ENTERPRISE_HOME_FREE_CONTENT,
  ENTERPRISE_DAILY_PASSPORT,
  BUSINESS_TRAEGER,
  BUSINESS_TRUST_HELP,
  BUSINESS_HILTON_LOGO,
  BUSINESS_MINESSOTA_LOGO,
  BUSINESS_TRUST_SIMPLE,
  BUSINESS_TRUST_KEEP,
  BUSINESS_ACME_DASHBOARD,
  BUSINESS_FL_LOGO,
  BUSINESS_IMAGE_4,
  BUSINESS_RSL_LOGO,
  BUSINESS_VIDANGEL_LOGO,
  BUSINESS_BYUSP_LOGO,
  BUSINESS_SLCCHAMBERS_LOGO,
  BUSINESS_PASSPORT_ASSESSMENT,
  BUSINESS_STATUSCLEAR,
  BUSINESS_HEALTH_STANDARD,
  BUSINESS_CD_FREE,
  HOME_HEALTH_N_SAFETY_DESKTOP,
  HOME_HEALTH_N_SAFETY_MOBILE,
  HOME_PRIVACY,
  HOME_SOC2,
  HOME_HIPAA,
  HOME_BENCHMARKS,
  BUSINESS_PASSPORT_CLEAR,
  BUSINESS_PASSPORT_NOCLEAR,
  PRODUCT_PAGE_ICON_FOLDER,
};
